@mixin maxWidth($breakpoint) {
  @media only screen and (max-width: $breakpoint + 'px') {
    @content;
  }
}

@mixin minWidth($breakpoint) {
  @media only screen and (min-width: $breakpoint + 'px') {
    @content;
  }
}

@mixin minMaxWidth($breakpoint-min, $breakpoint-max) {
  @media only screen and (min-width: $breakpoint-min + 'px') and (max-width: $breakpoint-max + 'px') {
    @content;
  }
}

@mixin noTouchHover {
   @media not all and (-moz-touch-enabled: 1),
     not all and (pointer: coarse),
     not all and (-ms-high-contrast: none) and (max-width: 600px) {
       &:hover {
         @content;
       }
   }
}

@mixin scrollBar($barColor: var(--clr-n1), $thumbColor: var(--clr-n3), $radius: 0px, $size: 6px) {
  scrollbar-color: $thumbColor $barColor;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    background-color: $barColor;
    width: $size;
    height: $size;
    border-radius: $radius;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $radius;
    background-color: $thumbColor;
  }
}
