:root {
  // fonts
  --font-main: ALS Hauss, Arial, sans-serif;
  --font-secondary: Mont, Arial, sans-serif;

  // colors
  --clr-accent-light: #1f3079;
  --clr-accent-main: #1b2b72;
  --clr-accent-semidark: #111f5c;
  --clr-accent-dark: #111d52;
  --clr-decor-light: #ff8982;
  --clr-decor-semilight: #ff6161;
  --clr-decor-main: #fc5151;
  --clr-decor-dark: #ce3535;
  --clr-text-main: #717899;
  --clr-text-secondary: #989eb4;
  --clr-text-accent: #162151;
  --clr-n1: #fff;
  --clr-n2: #f6f6fd;
  --clr-n3: #e5eaf4;
  --clr-n4: #e1e7f4;
  --clr-n5: #d6ddec;
  --clr-n6: #d5dbe8;
  --clr-n7: #bbbfd1;
  --clr-n8: #c2c3f0;
  --clr-n9: #9fa0e8;
  --clr-n10: #7678e0;
  --clr-n11: #4a4ce2;
  --clr-n12: #818dc0;
  --clr-n13: #2e3d7d;
  --clr-n14: #00cf91;
  --clr-n15: #505778;

  --clr-dev1: #472f92;
  --clr-dev2: #62bb46;
}
