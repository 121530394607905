@font-face {
  font-family: 'ALS Hauss';
  src: url('/fonts/ALSHauss-Regular.woff2') format('woff2'),
      url('/fonts/ALSHauss-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Hauss';
  src: url('/fonts/ALSHauss-Medium.woff2') format('woff2'),
      url('/fonts/ALSHauss-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Hauss';
  src: url('/fonts/ALSHauss-Bold.woff2') format('woff2'),
      url('/fonts/ALSHauss-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mont';
  src: url('/fonts/Mont-Bold.woff2') format('woff2'),
      url('/fonts/Mont-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}



